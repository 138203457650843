import { Outlet } from "react-router-dom";
import { Col } from "../../components/Col";
import PublicMenu from "../menus/public";
const UserLayout = () => {
  return (
    <Col className="items-center w-screen h-screen">
      <Col className="border-b border-slate-200 w-full items-center">
        <Col className="max-w-[1440px] w-full px-8">
          <PublicMenu />
        </Col>
      </Col>
      <Col className="h-full w-full max-w-[1440px] pl-4 md:pl-6">
        <Outlet />
      </Col>
    </Col>
  );
};

export default UserLayout;

import { PropsWithChildren } from "react";
import { Col } from "../../../../components/Col";
import clsx from "clsx";

const DevSectionLayout = (
  props: PropsWithChildren<{
    title: string;
    details?: string;
    className?: string;
    detailsClassName?: string;
  }>
) => {
  return (
    <Col className={clsx("gap-y-8 w-full", props.className)}>
      <Col>
        <p className="text-[24px] font-[600]">{props.title}</p>
        {props.details ? (
          <p className={props.detailsClassName}>{props.details}</p>
        ) : null}
      </Col>
      {props.children}
    </Col>
  );
};

export default DevSectionLayout;

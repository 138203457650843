import { ReactNode } from "react";
import { Col } from "../components/Col";

const CenteredPage = ({
  children,
  className = "",
  pageClassName = "",
}: {
  children: ReactNode;
  className?: string;
  pageClassName?: string;
}) => {
  return (
    <Col
      className={`w-screen min-h-screen items-center justify-center ${pageClassName}`}
    >
      <div className={`w-full h-full max-w-[1024px] ${className}`}>
        {children}
      </div>
    </Col>
  );
};

export default CenteredPage;

export type Link = {
  label: string;
  url?: string;
  onClick?: () => void;
  icon: React.ReactNode;
};

const LinkButton = (props: Link) => {
  return (
    <a
      onClick={props.onClick ?? undefined}
      href={props.url ?? undefined}
      target={props.url ? "__blank" : undefined}
      className="flex items-center gap-2 bg-neutral-200 dark:bg-neutral-800 px-6 py-4 rounded-md hover:opacity-70 w-full cursor-pointer"
    >
      <span className="text-xl">{props.icon}</span>
      <p>{props.label}</p>
    </a>
  );
};

export default LinkButton;

import { atom, SetStateAction, useAtomValue, useSetAtom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";
import type { SyncStorage } from "jotai/utils/atomWithStorage";
import { validateJwt } from "../hooks/validateJwt";
import { UserRole } from "./registration";

export type UserHabitsFormAnswers = Record<string, string | string[] | number>;

export type UserStage = { stage_id: string; completed?: Date };

export type SessionUser = {
  _id: string;
  uid: string;
  email: string;
  first_name: string;
  last_name: string;
  birthdate: Date;
  phone: string;
  document: string;
  document_type: string;
  sex: string;
  gender: string;
  nickname: string;
  role: UserRole;
  verified: boolean;
  enabled: boolean;
  stages: UserStage[];
  updated: Date;
  created: Date;
};

export type Session = {
  user: SessionUser;
  token: string;
};

const sessionTypeAtom = atom<"stored" | "temporal">("stored");

const baseStorage = createJSONStorage<Session | null>(() => localStorage);
const storage: SyncStorage<Session | null> = {
  ...baseStorage,
  getItem: (key: any) => {
    const value = baseStorage.getItem(key);
    if (!value || typeof value !== "object") return value;

    const valid = validateJwt(value.token);
    if (!valid) {
      localStorage.removeItem(key);
      return null;
    }

    return value;
  },
};

const storedSessionAtom = atomWithStorage<Session | null>(
  "session",
  null,
  storage
);

// TODO:
const temporalSessionAtom = storedSessionAtom;

export const useSessionAtom = () => {
  const type = useAtomValue(sessionTypeAtom);
  return type === "stored" ? storedSessionAtom : temporalSessionAtom;
};

export const useInitSession = () => {
  const setSessionType = useSetAtom(sessionTypeAtom);
  const setStoredSession = useSetAtom(storedSessionAtom);
  const setTemporalSession = useSetAtom(temporalSessionAtom);
  return (session: Session, type: "stored" | "temporal") => {
    setSessionType(type);
    if (type === "stored") setStoredSession(session);
    else setTemporalSession(session);
  };
};

export const useUpdateSessionUser = () => {
  const sessionAtom = useSessionAtom();
  const setSession = useSetAtom(sessionAtom);
  return (update: SetStateAction<SessionUser>) =>
    setSession((session) => {
      if (!session) throw new Error();
      const newUser =
        typeof update === "function" ? update(session.user) : update;
      return { ...session, user: newUser };
    });
};

import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";

const HeaderCVPdfSection = () => {
  const styles = StyleSheet.create({
    section: {
      flexDirection: "row",
      alignItems: "center",
    },
    selfie: {
      width: "75px",
      height: "75px",
      borderRadius: "100%",
      marginRight: "16px",
    },
    name: { fontSize: "15px", fontWeight: 500 },
    jobTitle: { fontSize: "30px", fontWeight: 900, color: "#451cb5" },
  });

  const imageUrl =
    "https://pbs.twimg.com/profile_images/1658597002630840324/pWoQuQDl_400x400.jpg";

  return (
    <View style={styles.section}>
      <Image src={imageUrl} style={styles.selfie} />
      <View>
        <Text style={styles.name}>Hola, soy Sebastian Campos</Text>
        <Text style={styles.jobTitle}>Desarrollador web</Text>
      </View>
    </View>
  );
};

export default HeaderCVPdfSection;

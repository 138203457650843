import { useNavigate } from "react-router-dom";
import { Col } from "../../../components/Col";
import { Row } from "../../../components/Row";
import CenteredPage from "../../../layouts/centeredPage";
import DevSectionLayout from "../curriculum/components/sectionLayout";

import { IoLogoLinkedin } from "react-icons/io5";
import { BsTwitterX } from "react-icons/bs";
import { RiInstagramFill } from "react-icons/ri";
import { VscGithubInverted } from "react-icons/vsc";
import { FaLaptopFile } from "react-icons/fa6";
import LinkButton, { Link } from "./link";

const LinksPage = () => {
  const navigate = useNavigate();
  const directLinks: Link[] = [
    {
      label: "Twitter",
      url: "https://twitter.com/bastiampos",
      icon: <BsTwitterX />,
    },
    {
      label: "Instagram",
      url: "https://instagram.com/bastiampos",
      icon: <RiInstagramFill />,
    },
    {
      label: "LinkedIn",
      url: "https://linkedin.com/in/bastiampos",
      icon: <IoLogoLinkedin />,
    },
    {
      label: "Github",
      url: "https://github.com/bastiampos",
      icon: <VscGithubInverted />,
    },
  ];

  const resources: Link[] = [
    {
      label: "Currículum",
      onClick: () => navigate("/cv"),
      icon: <FaLaptopFile />,
    },
  ];

  const imageUrl =
    "https://pbs.twimg.com/profile_images/1658597002630840324/pWoQuQDl_400x400.jpg";

  return (
    <div className="overflow-x-hidden">
      <CenteredPage
        className="flex flex-col items-start justify-center gap-14 py-10"
        pageClassName="bg-neutral-100 dark:bg-neutral-900 dark:text-white px-4 md:pr-8"
      >
        <Row className="items-center gap-x-4">
          <div
            className="sm:w-16 w-12 sm:h-16 h-12 rounded-full bg-cover"
            style={{ backgroundImage: `url(${imageUrl})` }}
          />
          <Col className="text-left">
            <p className="text-xl lg:text-2xl font-[600]">Sebastian Campos</p>
            <p className="text-neutral-500">@bastiampos</p>
          </Col>
        </Row>
        <DevSectionLayout
          title="Enlaces directos"
          details="¡Conéctate conmigo en un instante! Encuentra mis perfiles públicos aquí. ¡Haz clic y únete a mi red de contactos, te espero al otro lado!"
          className="!gap-y-4"
          detailsClassName="!text-sm !font-[400] text-neutral-500"
        >
          <div className="grid sm:grid-cols-2 gap-4 w-ful">
            {directLinks.map((link) => (
              <LinkButton key={link.label} {...link} />
            ))}
          </div>
        </DevSectionLayout>
        <DevSectionLayout
          title="Recursos"
          details="Los siguientes enlaces te conducirán a una selección de recursos que he reunido cuidadosamente en un solo sitio: mis recomendaciones, archivos y todo aquello que considero digno de compartir." // ¡Explora y aprovecha al máximo esta valiosa colección de conocimientos!
          className="!gap-y-4"
          detailsClassName="!text-sm !font-[400] text-neutral-500"
        >
          <div className="grid sm:grid-cols-2 gap-4 w-ful">
            {resources.map((link) => (
              <LinkButton key={link.label} {...link} />
            ))}
          </div>
        </DevSectionLayout>
        <DevSectionLayout
          title="Escucha mi playlist favorita"
          details="¿Necesitas un poco de música para alegrarte el día? Descubre mi playlist favorita en Spotify, cuidadosamente seleccionada para levantar el ánimo y crear el ambiente perfecto. Desde éxitos clásicos hasta las últimas tendencias, ¡esta playlist tiene de todo! Dale play y deja que la música haga su magia."
          className="!gap-y-4"
          detailsClassName="!text-sm !font-[400] text-neutral-500"
        >
          <iframe
            src="https://open.spotify.com/embed/playlist/7pM1RshGU6gH7n8gt2Emlw?si=7422c9b82c254706&theme=0"
            height="400px"
            width="100%"
          />
        </DevSectionLayout>
      </CenteredPage>
    </div>
  );
};

export default LinksPage;

import { Row } from "./Row";

type Props = {
  title: string;
  type?: "success" | "error";
  description?: string;
  children: React.ReactNode;
  onClose?: () => void;
};

const Notification = ({
  type,
  children,
  onClose,
  title,
  description,
}: Props) => {
  let typeClassnames = "bg-green-200 text-green-900";

  if (type == "error") {
    typeClassnames = "bg-red-200 text-red-900";
  }

  return (
    <div className="toast-width z-[99999999999999]">
      <div
        className={`shadow-2xl ${typeClassnames} rounded-xl md:max-w-2xl px-3 pt-3 pb-4 sm:p-6 md:px-7 flex items-start sm:items-center gap-3 sm:gap-6 justify-between opacity-100`}
      >
        <div className="flex flex-1 items-center gap-4">
          <div className="flex flex-col h-full">
            <span
              className={`text-white ${
                type == "error" ? `bg-red-900` : `bg-green-900`
              } rounded-full w-5 h-5 text-center text-sm`}
              style={{ lineHeight: "20px" }}
            >
              {type == "success" ? `✓` : `!`}
            </span>
          </div>
          <Row className="flex-1 items-center justify-between flex-wrap md:flex-nowrap gap-2 sm:gap-6">
            {(title || description) && (
              <div className={!description && title ? "-mb-2" : ""}>
                {title && <p className="text-xs font-[600]">{title}</p>}
                {description && <p className="text-sm">{description}</p>}
              </div>
            )}
            {children && children}
          </Row>
        </div>
        <button onClick={onClose}>
          <svg
            className="w-5 h-5"
            fill="#6B8286"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  );
};
export default Notification;

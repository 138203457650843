import { useRef } from "react";
import useOnClickOutside from "../hooks/clickOutside";

type Props = {
  children: any;
  className?: string;
  onClick?: any;
  onClose: any;
};

const OutsideDiv = ({ children, className, onClick, onClose }: Props) => {
  const ref = useRef<any>();
  useOnClickOutside(ref, onClose);

  return (
    <div className={className} ref={ref} onClick={onClick ?? null}>
      {children}
    </div>
  );
};

export default OutsideDiv;

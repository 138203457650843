import clsx from "clsx";
import { MaterialIcon } from "../Icons";

const CloseModalButton = (props: {
  onClick: () => void;
  className?: string;
}) => (
  <button
    onClick={props.onClick}
    className={clsx(
      "flex items-center justify-center bg-neutral-700 text-neutral-300 p-2 hover:opacity-80 w-7 h-7 rounded-full",
      props?.className
    )}
  >
    <MaterialIcon className="text-[20px]">close</MaterialIcon>
  </button>
);

export default CloseModalButton;

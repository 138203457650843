import { Link, StyleSheet, Text, View } from "@react-pdf/renderer";

const IntroductionCVPdfSection = () => {
  const styles = StyleSheet.create({
    section: { marginTop: "10px" },
    text: { fontSize: "10px", fontWeight: 400 },
  });

  return (
    <View style={styles.section}>
      <Text style={styles.text}>
        {
          "Me apasiona dar vida a infinitas interfaces de usuario. Aporto una perspectiva extra, siempre priorizando performance y experiencia de usuario. Con base en mi experiencia, me integro a equipos que trabajan con metodologías ágiles, cumpliendo objetivos en tiempo y forma, y comunicandome activamente entre los miembros. Me instruyo en las nuevas tecnologías a medida que el proyecto lo requiera; estoy abierto al debate; dispuesto a buscar la mejor solución que cumpla los requerimientos establecidos; y adopto los procesos de seguridad y privacidad definidos."
        }
      </Text>
      <Text style={[styles.text, { marginTop: "8px" }]}>
        Contáctame vía email{" "}
        <Link
          style={{ color: "#000000" }}
          src="mailto:bastiampos.job@gmail.com"
        >
          bastiampos.job@gmail.com
        </Link>{" "}
        ¡Crearemos valor juntos sin lugar a dudas!
      </Text>
    </View>
  );
};

export default IntroductionCVPdfSection;

import { CenteredSection } from "../../../components/CenteredSection";

const DevIntroductionSection = () => {
  return (
    <CenteredSection containerClassName="py-10 lg:py-16 bg-neutral-200 dark:bg-neutral-800">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 md:gap-12 text-left">
        <p>
          Me apasiona dar vida a infinitas interfaces de usuario. Aporto una
          perspectiva extra, siempre priorizando performance y experiencia de
          usuario.
          <br />
          <br />
          Con base en mi experiencia, me integro a equipos que trabajan con
          metodologías ágiles, cumpliendo objetivos en tiempo y forma, y
          comunicandome activamente entre los miembros.
        </p>
        <p>
          Me instruyo en las nuevas tecnologías a medida que el proyecto lo
          requiera; estoy abierto al debate; dispuesto a buscar la mejor
          solución que cumpla los requerimientos establecidos; y adopto los
          procesos de seguridad y privacidad definidos.
          <br />
          <br />
          Puedes contactarme vía email:{" "}
          <a
            href="mailto:bastiampos.job@gmail.com"
            target="__blank"
            className="underline"
          >
            bastiampos.job@gmail.com
          </a>
          <br />
          <span className="font-[600]">
            ¡Crearemos valor juntos sin lugar a dudas!
          </span>
        </p>
      </div>
    </CenteredSection>
  );
};

export default DevIntroductionSection;

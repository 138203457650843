import { Tooltip } from "react-tooltip";
import { Col } from "./Col";

type Props = {
  children: React.ReactNode;
  tooltipId: string;
  tooltipText: string;
  className?: string;
  containerClassName?: string;
};

const CustomTooltip = ({
  children,
  tooltipId,
  tooltipText,
  className,
  containerClassName,
}: Props) => {
  return (
    <Col className={containerClassName}>
      <Col data-tooltip-id={tooltipId} data-tooltip-content={tooltipText}>
        {children}
      </Col>
      {tooltipText ? <Tooltip className={className} id={tooltipId} /> : null}
    </Col>
  );
};

export default CustomTooltip;

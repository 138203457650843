import { Col } from "../../../../components/Col";
import { Row } from "../../../../components/Row";
import clsx from "clsx";
import { format } from "date-fns";
import { CurriculumProject } from "../../../../stores/cv";

const ProjectCard = ({ project }: { project: CurriculumProject }) => {
  return (
    <Col className="mw-full p-2 rounded-[24px] bg-[#272727] gap-y-2">
      <div
        className="w-full h-[175px] md:h-[300px] bg-cover bg-center bg-white rounded-[16px]"
        style={{ backgroundImage: `url(${project.imageSrc})` }}
      />
      <Col className="text-start text-[#848484] px-2 text-xs md:text-sm">
        <Row className="items-center justify-between">
          <Row className="items-center gap-x-1">
            <div
              className={clsx("w-[10px] h-[10px] rounded-full bg-[#C32412]", {
                "!bg-[#268D61]": project.productionUrl,
              })}
            />
            <p className="items-center">
              <a
                href={project?.productionUrl ?? undefined}
                target="__blank"
                className="text-[#A6A7A0] underline hover:text-purple-200"
              >
                {project.title}{" "}
              </a>
            </p>
          </Row>
          <p>
            {project.finished
              ? format(new Date(project.finished), "yyyy")
              : "Actualidad"}
          </p>
        </Row>
      </Col>
    </Col>
  );
};

export default ProjectCard;

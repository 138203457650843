import clsx from "clsx";
import { Col } from "../../../../components/Col";
import { Row } from "../../../../components/Row";
import { useMemo } from "react";
import { MaterialIcon } from "../../../../components/Icons";

type Props = {
  onClose: () => void;
  filename: string;
  updateFilename: (newFilename: string) => void;
};

const RightDownloadCVModalSection = (props: Props) => {
  const filenameRecommendations = useMemo(() => {
    const recommendations = [
      "sebastian_campos",
      "campos_sebastian",
      "cv_sebastian_campos",
      "cv_campos_sebastian",
      "sebastian_campos_cv",
      "campos_sebastian_cv",
    ];

    return recommendations.filter((option) => option !== props.filename);
  }, [props.filename]);
  return (
    <Col
      className={clsx(
        "col-span-2 xl:bg-neutral-500 xl:dark:bg-neutral-700 pl-4 pr-2 sm:px-6 xl:p-6 xl:flex-1 xl:h-[450px]"
      )}
    >
      <Col className="xl:justify-between xl:h-full xl:flex-1 gap-y-6">
        <Col className="text-neutral-900 dark:text-neutral-500 gap-y-6">
          <p>
            Escoge el nombre del documento para que tenga el formato de tu base
            de datos.
          </p>
          <Row className="border-b border-white flex-1 text-white">
            <input
              type="text"
              name="filename"
              value={props.filename}
              className="!bg-transparent !borde-none !outline-none flex-1"
              onChange={(e) => props.updateFilename(e.currentTarget.value)}
            />
            <span>.pdf</span>
          </Row>
          <Col className="gap-y-1">
            <p className="text-xs">Sugerencias</p>
            <Row className="flex-wrap gap-[6px] items-center">
              {filenameRecommendations.map((option, index) => (
                <button
                  key={index}
                  onClick={() => props.updateFilename(option)}
                  className="text-xs px-2 bg-white text-neutral-900 rounded-full"
                >
                  {option}
                </button>
              ))}
            </Row>
          </Col>
        </Col>
        <Col className="bg-neutral-200 p-2 rounded-[8px] gap-y-4">
          <Row className="items-center gap-x-2">
            <MaterialIcon className="text-neutral-500">
              description
            </MaterialIcon>
            {props.filename}.pdf
          </Row>
        </Col>
      </Col>
    </Col>
  );
};

export default RightDownloadCVModalSection;

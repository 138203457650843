import clsx from "clsx";
import { PropsWithChildren } from "react";
import ReactDOM from "react-dom";

const EmptyModal = (props: PropsWithChildren<{ className?: string }>) => {
  const child = (
    <div
      className={clsx(
        "fixed inset-0 flex items-center justify-center bg-neutral-900 bg-opacity-60 z-[1000000000000] px-3 md:px-6 lg:px-0",
        props?.className
      )}
    >
      <div>{props.children}</div>
    </div>
  );

  return ReactDOM.createPortal(child, document.querySelector("body")!);
};

export default EmptyModal;

import clsx from "clsx";
import { MaterialIcon } from "../../../components/Icons";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { Col } from "../../../components/Col";
import { useState } from "react";
import EmptyModal from "../../../components/modals";
import OutsideDiv from "../../../components/OutsideDiv";

const ImageCard = ({
  image,
  index,
}: {
  image: { src: string; date?: Date };
  index?: number;
}) => {
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => setShowModal(false);
  return (
    <>
      {showModal ? (
        <EmptyModal>
          <OutsideDiv
            className="bg-white border rounded-[24px] p-1 relative"
            onClose={closeModal}
          >
            <img
              alt=""
              src={image.src}
              className={clsx("w-full h-full max-h-[600px] rounded-[24px]", {
                "rotate-180":
                  index &&
                  (index === 2 || index === 3 || index === 5 || index === 6),
              })}
            />
            <Col
              className="bg-white rounded-full p-1 absolute top-4 right-4 shadow-md cursor-pointer"
              onClick={closeModal}
            >
              <MaterialIcon>close</MaterialIcon>
            </Col>
          </OutsideDiv>
        </EmptyModal>
      ) : null}
      <div className="relative group" onClick={() => setShowModal(true)}>
        <div
          style={{ backgroundImage: `url("${image.src}")` }}
          className={clsx("h-[300px] w-full bg-cover bg-center", {
            "rotate-180":
              index &&
              (index === 2 || index === 3 || index === 5 || index === 6),
          })}
        />
        <Col className="bg-[#00000050] group-hover:hidden absolute inset-0 items-end justify-end p-4">
          {image.date ? (
            <p className="text-xs flex items-center gap-x-1 text-white">
              <MaterialIcon className="text-lg">today</MaterialIcon>{" "}
              {format(new Date(image.date), "MMMM dd, yyyy", {
                locale: es,
              })}
            </p>
          ) : null}
        </Col>
      </div>
    </>
  );
};

export default ImageCard;

import { CenteredSection } from "../../../../components/CenteredSection";
import { defaultCurriculum } from "../../../../stores/cv";
import DevSectionLayout from "../components/sectionLayout";
import JobTimelineItem from "./jobItem";

{
  /* <p>Fue requerido la creación de una nueva aplicación moderna de gestión de turnos que remplazara el antiguo sistema en uso por el laboratorio. Me encargué de todo el desarrollo frontend desde el inicio basado en un diseño base previamente creado en Figma por un equipo de diseño UX/UI. Junto a un equipo backend del laboratorio se implementó todo la lógica de autenticación y reserva de turnos hasta lograr un MVP que permitiera el uso en un ambiente de producción.</p> */
}

const DevJobsTimelineSection = () => {
  return (
    <CenteredSection>
      <DevSectionLayout
        title="Experiencia Laboral"
        details="Línea del tiempo donde detallo todas aquellas actividades, conocimientos y aptitudes que he adquirido con el trabajo en una o más organizaciones."
      >
        <div className="gap-10 grid lg:grid-cols-2">
          {defaultCurriculum.jobs.map((job) => (
            <JobTimelineItem key={job.companyName} job={job} />
          ))}
        </div>
      </DevSectionLayout>
    </CenteredSection>
  );
};

export default DevJobsTimelineSection;

import { StyleSheet, Text, View } from "@react-pdf/renderer";
import CVPDFSection from "./components/section";
import { defaultCurriculum } from "../../stores/cv";
import { format } from "date-fns";

const EducationCVPdfSection = () => {
  const studies = defaultCurriculum.education;

  const styles = StyleSheet.create({
    view: {},
    title: { fontSize: "12px", fontWeight: 900 },
    companyName: { fontSize: "9px", fontWeight: 700, color: "#00000090" },
    description: {
      fontSize: "10px",
      fontWeight: 400,
      color: "#000000",
      marginTop: "5px",
    },
  });

  const formatDate = (date: Date) => format(new Date(date), "MM/yyyy");

  return (
    <CVPDFSection subtitle="Estudios">
      <View>
        {studies.map((study, index) => (
          <View
            key={index}
            style={[styles.view, { marginTop: index > 0 ? "10px" : "0px" }]}
          >
            <Text style={styles.title}>{study.title}</Text>
            <Text style={styles.companyName}>
              {study.place},{" "}
              {study.fromDate ? `${formatDate(study.fromDate)} a ` : ""}
              {study.toDate ? formatDate(study.toDate) : "Actualidad"}
            </Text>
            <Text style={styles.description}>{study.description}</Text>
          </View>
        ))}
      </View>
    </CVPDFSection>
  );
};

export default EducationCVPdfSection;

import {
  Document,
  Link,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import HeaderCVPdfSection from "./header";
import IntroductionCVPdfSection from "./introduction";
import WorkExperienceCVPdfSection from "./workExperience";
import ProjectsCVPdfSection from "./projects";
import EducationCVPdfSection from "./education";

const CVDocument = () => {
  const styles = StyleSheet.create({
    page: {
      padding: "20px",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    footerContainer: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "15px",
    },
    footerText: {
      fontSize: "10px",
      color: "#00000090",
    },
  });

  return (
    <Document
      title="Sebastian Campos Currículum"
      author="Sebastian Campos"
      subject="curriculum"
      language="es"
      pageMode="fullScreen"
      pageLayout="oneColumn"
    >
      <Page size="A4" style={styles.page}>
        <View>
          <HeaderCVPdfSection />
          <IntroductionCVPdfSection />
          <WorkExperienceCVPdfSection />
          <ProjectsCVPdfSection />
          <EducationCVPdfSection />
        </View>
        <View fixed style={styles.footerContainer}>
          <Text style={styles.footerText}>
            Ver currículum actualizado en mi sitio web{" "}
            <Link style={{ color: "" }} src="https://bastiampos.vercel.app/cv">
              bastiampos.vercel.app
            </Link>
          </Text>
          <Text style={styles.footerText}>@bastiampos</Text>
        </View>
      </Page>
    </Document>
  );
};

export default CVDocument;

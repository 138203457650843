import Button from "../../../../components/Button";
import { MaterialIcon } from "../../../../components/Icons";
import DownloadCVModal from "../downloadCVModal";
import { useState } from "react";

const DownloadCVButton = () => {
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal ? <DownloadCVModal onClose={closeModal} /> : null}
      <Button
        onClick={openModal}
        className="border border-neutral-900 dark:border-white fixed bg-white dark:bg-neutral-900 justify-between lg:justify-center rounded-b-[0px] w-full rounded-t-[16px] py-4 left-0 bottom-0 lg:!rounded-full lg:!bg-transparent lg:!py-2 lg:!relative hover:!opacity-100 hover:lg:!opacity-70"
      >
        <p className="text-sm font-[600] text-neutral-900 dark:text-white">
          Descargar CV en PDF
        </p>
        <MaterialIcon className="text-neutral-900 dark:text-white lg:hidden">
          file_download
        </MaterialIcon>
      </Button>
    </>
  );
};

export default DownloadCVButton;

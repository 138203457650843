import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { PropsWithChildren } from "react";

const CVPDFSection = (props: PropsWithChildren<{ subtitle: string }>) => {
  const styles = StyleSheet.create({
    view: { marginTop: "15px" },
    subtitle: { fontSize: "12px", fontWeight: 600, color: "#451cb5" },
    line: {
      width: "100%",
      height: "1px",
      backgroundColor: "#451cb5",
      marginTop: "2px",
      marginBottom: "8px",
    },
  });

  return (
    <View style={styles.view}>
      <Text style={styles.subtitle}>{props.subtitle}</Text>
      <View style={styles.line} />
      {props.children}
    </View>
  );
};

export default CVPDFSection;

import decodeJwt from "jwt-decode";
import { addSeconds } from "date-fns";

export const validateJwt = (token: string) => {
  const decoded = decodeJwt(token) as { exp: number };
  const epoch = new Date(0);
  const expiracyDate = addSeconds(epoch, decoded.exp);

  if (expiracyDate < new Date()) {
    return false;
  }
  return true;
};

import clsx from "clsx";
import { Col } from "./Col";
import { PropsWithChildren } from "react";

export const CenteredSection = (
  props: PropsWithChildren<{ className?: string; containerClassName?: string }>
) => {
  const { className, containerClassName } = props;
  return (
    <Col className={clsx([containerClassName, "items-center px-4 md:px-6"])}>
      <Col className={clsx([className, "w-full max-w-[1024px]"])}>
        {props.children}
      </Col>
    </Col>
  );
};

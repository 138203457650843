import DevHeaderSection from "./header";
import DevIntroductionSection from "./introduction";
import DevProjectsSection from "./Projects";
import DevFooterSection from "./footer";
import DevJobsTimelineSection from "./jobsTimeline";
import { Col } from "../../../components/Col";

const CurriculumPage = () => {
  return (
    <div className="pb-16 md:pb-0 bg-neutral-100 dark:bg-neutral-900 dark:text-white">
      <DevHeaderSection />
      <DevIntroductionSection />
      <Col className="py-20 gap-y-[100px]">
        <DevJobsTimelineSection />
        <DevProjectsSection />
      </Col>
      <DevFooterSection />
    </div>
  );
};

export default CurriculumPage;

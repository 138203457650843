// import ScrollContainer from "react-indiana-drag-scroll";
// import { Col } from "../../../../components/Col";
import ProjectCard from "./card";
import { CenteredSection } from "../../../../components/CenteredSection";
import { defaultCurriculum } from "../../../../stores/cv";
import DevSectionLayout from "../components/sectionLayout";

const DevProjectsSection = () => {
  return (
    <CenteredSection className="gap-y-4">
      <DevSectionLayout
        title="Proyectos"
        details="Colección de proyectos en los que he participado o he desarrollado, pertenecientes tanto a una jornada de aprendizaje/práctica como de una jornada laboral. Conoce el detalle de cada proyecto cliqueando sobre él."
      >
        <div className="gap-6 grid lg:grid-cols-2">
          {defaultCurriculum.projects.map((project, index) => (
            <ProjectCard key={index} project={project} />
          ))}
        </div>
      </DevSectionLayout>
    </CenteredSection>
  );
};

export default DevProjectsSection;

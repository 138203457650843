import { PropsWithChildren } from "react";

import { Col } from "../../../components/Col";
import { MaterialIcon } from "../../../components/Icons";
import { Row } from "../../../components/Row";
import CenteredPage from "../../../layouts/centeredPage";

import pic1 from "./assets/1.png";
import pic2 from "./assets/2.png";
import pic3 from "./assets/3.png";
import pic4 from "./assets/4.png";
import pic5 from "./assets/5.png";
import pic6 from "./assets/6.png";
import pic7 from "./assets/7.png";
import pic8 from "./assets/8.png";
import pic9 from "./assets/9.png";
import pic10 from "./assets/10.png";
import pic11 from "./assets/11.png";
import pic12 from "./assets/12.png";
import pic13 from "./assets/13.png";
import pic14 from "./assets/14.png";
import pic15 from "./assets/15.png";
import pic16 from "./assets/16.png";

import ImageCard from "./ImageCard";

const Ani = () => {
  document.title = "🎊🎉Feliz cumpleaños🎉🎊";

  const Section = (
    props: PropsWithChildren<{ icon?: string; title: string }>
  ) => (
    <Col>
      <Row className="items-center gap-x-2">
        {props.icon ? <MaterialIcon>{props.icon}</MaterialIcon> : null}
        <p className="text-[24px] font-[600]">{props.title}</p>
      </Row>
      {props.children}
    </Col>
  );

  const pics = [
    { src: pic1, date: new Date("2022-12-01T12:00:00") },
    { src: pic2, date: new Date("2022-12-01T12:00:00") },
    { src: pic3, date: new Date("2022-12-01T12:00:00") },
    { src: pic4, date: new Date("2022-12-01T12:00:00") },
    { src: pic5, date: new Date("2022-12-02T12:00:00") },
    { src: pic6, date: new Date("2023-07-14T12:00:00") },
    { src: pic7, date: new Date("2023-07-14T12:00:00") },
    { src: pic8, date: new Date("2023-07-14T12:00:00") },
    { src: pic9, date: new Date("2023-07-15T12:00:00") },
    { src: pic10, date: new Date("2023-07-15T12:00:00") },
  ];

  const kekoPics = [pic11, pic12, pic13, pic14, pic15, pic16];

  return (
    <CenteredPage>
      <Col className="px-4 py-10 gap-y-10">
        <Row className="items- justify-between">
          <Row className="items-center gap-x-4">
            <img
              className="border-4 border-pink-600 rounded-full w-20 h-20"
              src="https://scontent.xx.fbcdn.net/v/t1.15752-9/428401369_1543330469542087_3284050342532542402_n.jpg?stp=cp0_dst-jpg&_nc_cat=101&ccb=1-7&_nc_sid=510075&_nc_ohc=GQKXXZY1Mm0AX-RRKup&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent.xx&oh=03_AdRZcHqaReObBU3-icYkocSeFItn0MwLLkgqT11nfxs7Ow&oe=66018860"
              alt=""
            />
            <Col>
              <p className="font-[500] text-[28px] leading-[28px]">¡Querida</p>
              <p className="text-[40px] text-pink-600 font-[900] leading-[40px]">
                Plimplina!
              </p>
            </Col>
          </Row>
          <Col>
            <p className="text-sm flex items-center gap-x-2 text-gray-700">
              <MaterialIcon>today</MaterialIcon> Febrero 24, 2024
            </p>
          </Col>
        </Row>
        <Col className="gap-y-10">
          <p className="tracking-5 text-black text-sm">
            🎊🎉 ¡Feliz cumpleaños! En este día especial, quiero dedicarte unas
            palabras llenas de cariño y gratitud. Hoy celebramos a una persona
            increíble que ilumina nuestras vidas con su alegría y su espíritu
            inigualable.
            <br />
            <br />
            Primero que todo, quiero resaltar lo divertida que eres. Siempre
            logras sacarme una sonrisa, incluso en los días más grises. Tu
            sentido del humor contagioso y tu energía positiva hacen que cada
            momento a tu lado sea memorable. No hay duda de que eres la persona
            perfecta para alegrar cualquier reunión.
            <br />
            <br />
            En este día tan especial, quiero agradecerte sinceramente por ser
            una amiga tan increíble. Tu apoyo incondicional y tus palabras
            alentadoras han sido mi roca en momentos difíciles. La manera en que
            siempre estás ahí para escucharme y apoyarme significa el mundo para
            mí. No hay palabras suficientes para expresar lo agradecido que
            estoy por tenerte en mi vida.
            <br />
            <br />
            Aunque la distancia física nos separe, quiero que sepas cuánto te
            extraño a diario. Cada día es un recordatorio de lo afortunado que
            soy de tener una amiga como tú. Tu ausencia se siente, pero también
            sé que la amistad verdadera supera cualquier distancia. Espero con
            ansias el día en que podamos reunirnos más a menudo, disfrutar de
            nuestras risas y compartir esos momentos especiales que solo tú y yo
            entendemos.
            <br />
            <br />
            En este cumpleaños, deseo para ti toda la felicidad del mundo. Que
            este nuevo año de vida esté lleno de alegría, éxitos y experiencias
            inolvidables. Espero que tengas un día tan maravilloso como eres tú.
            <br />
            <br />
            ¡Feliz cumpleaños, Ani! Te envío un abrazo enorme y todo mi cariño
            en este día tan especial. 🎉🎊
            <br />
            <br />
            Con amor, Seba
          </p>
          <Section title="Algunos recuerdos juntos">
            <p className="tracking-5 text-black text-sm mb-6">
              Bienvenidos a la encantadora galería de recuerdos, donde cada
              imagen cuenta una historia única de las inolvidables visitas de
              Ani. A través de estas fotografías, capturamos momentos llenos de
              risas, alegría y amistad sincera.
              <br />
              <br />
              Cada imagen es un testimonio de los hermosos momentos que hemos
              compartido juntos, y aunque las visitas de Ani puedan ser
              esporádicas, cada encuentro es un tesoro que atesoro con cariño.
              Estas imágenes son testigos de la magia de su presencia y de la
              chispa que enciende cada reunión.
              <br />
              <br />
              Pero la historia no termina aquí; más bien, esta galería es solo
              el comienzo. Mi deseo es seguir recolectando más recuerdos,
              llenando cada espacio con nuevas aventuras, risas compartidas y
              momentos especiales. Porque la vida se trata de construir un
              tesoro de momentos, y con Ani, cada momento es una joya.
              <br />
              <br />
              Así que, disfruten de esta galería, sumérjanse en los recuerdos y
              prepárense para crear muchos más. ¡Que la amistad y la alegría
              sigan llenando cada página de nuestro álbum de recuerdos!
            </p>
            <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-4">
              {pics.map((pic, i) => (
                <ImageCard key={pic.src} image={pic} index={i} />
              ))}
            </div>
          </Section>
          <Section title="Y algunos otros">
            <p className="tracking-5 text-black text-sm mb-6">
              Esta colección sin contexto, capturados con cariño y creatividad.
              Cada imagen es una instantánea de momentos especiales, con el
              toque distintivo de la magia keko. Agradecemos a wines por
              capturar estas joyas visuales que nos transportan a través del
              tiempo.
            </p>
            <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-4">
              {kekoPics.map((pic) => (
                <ImageCard key={pic} image={{ src: pic }} />
              ))}
            </div>
          </Section>
        </Col>
      </Col>
    </CenteredPage>
  );
};

export default Ani;

import { FaGithub, FaSquareXTwitter, FaLinkedin } from "react-icons/fa6";

import { CenteredSection } from "../../../components/CenteredSection";
import { Col } from "../../../components/Col";
import { MaterialIcon } from "../../../components/Icons";
import { Row } from "../../../components/Row";

import DownloadCVButton from "./components/DownloadCVButton";
import OpenToWorkBadge from "./components/OpenToWorkBadge";
import CustomTooltip from "../../../components/CustomTooltip";

const DevHeaderSection = () => {
  const imageUrl =
    "https://pbs.twimg.com/profile_images/1658597002630840324/pWoQuQDl_400x400.jpg";

  const items = [
    {
      icon: <MaterialIcon className="text-lg">email</MaterialIcon>,
      tooltipId: "email",
      tooltipText: "Email",
      link: "mailto:bastiampos.job@gmail.com",
    },
    {
      icon: <FaLinkedin className="!text-lg" />,
      tooltipId: "linkedin",
      tooltipText: "Linkedin",
      link: "https://linkedin.com/in/bastiampos",
    },
    {
      icon: <FaGithub className="!text-lg" />,
      tooltipId: "github",
      tooltipText: "GitHub",
      link: "https://github.com/bastiampos",
    },

    {
      icon: <FaSquareXTwitter className="!text-lg" />,
      tooltipId: "X",
      tooltipText: "X",
      link: "https://x.com/bastiampos",
    },
  ];

  return (
    <CenteredSection containerClassName="py-10 md:py-20">
      <Row className="md:justify-between justify-center md:items-end gap-6">
        <Col className="gap-y-4">
          <Row className="items-center gap-x-4">
            <div
              className="sm:w-16 w-12 sm:h-16 h-12 rounded-full bg-cover"
              style={{ backgroundImage: `url(${imageUrl})` }}
            />
            <Col className="text-left">
              <p className="text-sm sm:text-base tracking-4">
                Hola, soy Sebastian Campos
              </p>
              <p className="font-[600] text-[24px] tracking-4 sm:text-[24px] md:text-[32px] leading-[32px]">
                Desarrollador web
              </p>
            </Col>
          </Row>
          <Row className="items-center gap-2 flex-wrap md:flex-nowrap">
            <OpenToWorkBadge />
            {items.map((item) => (
              <CustomTooltip
                tooltipId={item.tooltipId}
                tooltipText={item.tooltipText}
              >
                <a
                  href={item.link}
                  target="__blank"
                  className="flex bg-neutral-200 dark:bg-neutral-800 text-black dark:text-white hover:opacity-70 h-[30px] rounded-full w-[30px] items-center justify-center"
                >
                  {item.icon}
                </a>
              </CustomTooltip>
            ))}
          </Row>
        </Col>
        <Col className="gap-y-4 items-end">
          <p className="text-sm hidden lg:block">
            ¿Sin tiempo para navegar por todo el sitio?
          </p>
          <DownloadCVButton />
        </Col>
      </Row>
    </CenteredSection>
  );
};

export default DevHeaderSection;

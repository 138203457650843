import clsx from "clsx";
import { PropsWithChildren } from "react";

type Props = {
  onClick: () => void;
  className?: string;
  icon?: React.ReactNode;
};

const Button = (props: PropsWithChildren<Props>) => {
  return (
    <button
      onClick={props.onClick}
      className={clsx(
        props.className,
        "flex items-center gap-x-2 px-6 py-2 hover:opacity-70"
      )}
    >
      {props?.icon}
      {props.children}
    </button>
  );
};

export default Button;

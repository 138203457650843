import { TypeOf, date, object, string, enum as zEnum } from "zod";

export const jobSchema = object({
  jobTitle: string(),
  description: string(),
  cvDescription: string(),
  imageSrc: string().url().optional(),
  fromDate: date(),
  toDate: date().optional(),
  skills: string().array().optional(),

  companyName: string(),
  companyImage: string().optional(),
  companyWebsite: string().optional(),
});
export type Job = TypeOf<typeof jobSchema>;

export const curriculumProjectSchema = object({
  title: string(),
  description: string(),
  imageSrc: string().url().optional(),
  fromDate: date().optional(),
  toDate: date().optional(),

  job: jobSchema.optional(),

  type: zEnum(["project", "demo"]),
  category: zEnum(["subscription", "ecommerce", "game", "schedule", "other"]),
  finished: date().optional(),
  productionUrl: string().url().optional(),
  repositoryUrl: object({
    type: zEnum(["public", "private"]),
    url: string().url().optional(),
  }),
});
export type CurriculumProject = TypeOf<typeof curriculumProjectSchema>;

export const curriculumStudySchema = object({
  title: string(),
  description: string(),
  place: string(),
  imageSrc: string().url(),
  fromDate: date(),
  toDate: date().optional(),
});
export type CurriculumStudy = TypeOf<typeof curriculumStudySchema>;

export const curriculumLanguageSchema = object({
  title: string(),
  description: string(),
  level: string(),
  imageSrc: string().url(),
});
export type CurriculumLanguage = TypeOf<typeof curriculumLanguageSchema>;

export const curriculumSchema = object({
  selfie: string().url(),
  introduction: string(),
  jobs: jobSchema.array(),
  projects: curriculumProjectSchema.array(),
  education: curriculumStudySchema.array(),
  languages: curriculumLanguageSchema.array(),
  filename: string(),
});
export type Curriculum = TypeOf<typeof curriculumSchema>;

export const curriculumSectionsSchema = zEnum([
  "introduction",
  "workExperience",
  "projects",
  "education",
  "selfie",
  "languages",
]);
export type CurriculumSections = TypeOf<typeof curriculumSectionsSchema>;

export const curriculumSections: { title: string; key: CurriculumSections }[] =
  [
    { title: "Foto", key: "selfie" },
    { title: "Introducción", key: "introduction" },
    { title: "Experiencia Laboral", key: "workExperience" },
    { title: "Proyectos", key: "projects" },
    { title: "Estudios", key: "education" },
    { title: "Idiomas", key: "languages" },
  ];

const labtoryJob = {
  companyImage: "https://labtory.com.ar/wp-content/uploads/2020/08/g98.ico",
  companyName: "Labtory SA",
  companyWebsite: "https://labtory.com.ar/",
  jobTitle: "Full Stack Developer",
  fromDate: new Date("2022-04-01"),
  description: `Participo en equipo activamente con el objetivo de desarrollar aplicaciones web dirigidas a clientes directos de la empresa.
    <br /><br />
    <ul class="list-inside pl-5 flex flex-col gap-y-8">
      <li type="disc"><b>Rewell by Heritas</b>, compra de suscripciones al programa Rewell, formularios, visualización de resultados, gestión de turnos, generación de documentos PDFs, gestión de roles de usuario con su panel específico (Admin y Coach), integraciones con: CRM, analíticas, encuestas, referidos, etc.<br /><br />Mediante un equipo remoto tenemos ceremonias SCRUM para la coordinación del proyecto por sprints.</li>
      <li type="disc"><b>Laboratorio D'Agostino Bruno</b>, sistema de gestión de turnos y visualización de resultados de laboratorios</li>
    </ul>
    `,
  cvDescription:
    "Participo en equipo activamente con el objetivo de desarrollar aplicaciones web dirigidas a clientes directos de la empresa. En cada proyecto, tenemos ceremonias SCRUM para la coordinación del sprint, creación de objetivos, comunicación con los equipos de infra, análisis, diseño, y marketing",
  skills: ["Typescript", "ReactJS", "Vite", "NodeJS", "Express", "MongoDB"],
};

export const defaultCurriculum: Curriculum = {
  selfie: "",
  introduction: "",
  jobs: [
    labtoryJob,
    {
      companyImage:
        "https://media.licdn.com/dms/image/C4D0BAQH2LlU0aFu44Q/company-logo_100_100/0/1674764507384/devlabshq_logo?e=1717027200&v=beta&t=JlImNpIaFK1WUutZWD4krW5Y979SkcIbnSz64NcHDms",
      companyName: "DevLabs",
      companyWebsite: "https://www.linkedin.com/in/javierolivieri/",
      jobTitle: "React Native Developer",
      fromDate: new Date("2022-03-01"),
      toDate: new Date("2022-03-31"),
      description:
        "Apoyo en el desarrollo de aplicaciones moviles dirigidas a clientes en el extranjero. Mediante un sistema de SCRUM inicié contribuyendo con la resolución de bugs y a medida que fui conociendo los proyectos aporté con la implementación de pequeñas funcionalidades.",
      cvDescription:
        "Apoyo en el desarrollo de aplicaciones moviles dirigidas a clientes en el extranjero. Mediante un sistema de SCRUM inicié contribuyendo con la resolución de bugs y a medida que fui conociendo los proyectos aporté con la implementación de pequeñas funcionalidades.",
      skills: ["Typescript", "Reac Native"],
    },
  ],
  projects: [
    {
      type: "project",
      category: "subscription",
      title: "Rewell",
      fromDate: new Date("2022-04-01"),
      description:
        "Compra de suscripciones al programa Rewell, formularios, visualización de resultados, gestión de turnos, generación de documentos PDFs, gestión de roles de usuario con su panel específico (Admin y Coach), integraciones con: CRM, analíticas, encuestas, referidos, etc.",
      job: labtoryJob,
      productionUrl: "https://getrewell.com",
      imageSrc:
        "https://getrewell.com/wp-content/uploads/2023/05/mano-rewell-861x1024.png",
      repositoryUrl: { type: "private" },
    },
    {
      type: "project",
      category: "schedule",
      title: "Laboratorio D'Agostino Bruno",
      description:
        "Sistema de gestión de turnos y visualización de resultados de laboratorio.",
      job: labtoryJob,
      productionUrl: "https://portal.dagostino-bruno.com.ar",
      imageSrc: "https://i.imgur.com/PXVxnHe.png",
      finished: new Date("2022-12-31"),
      repositoryUrl: { type: "private" },
    },
  ],
  education: [
    {
      title: "Lic. Ciencias de la Computación",
      place: "Universidad de Buenos Aires",
      description:
        "Sólida formación teórica y práctica que me permitirá responder a las demandas tecnológicas y científicas actuales y futuras. CBC 2do cuatrimestre",
      imageSrc:
        "https://images.credly.com/images/9ab2bbf5-a5cd-4c36-9c5a-1ce7e0e0cd32/Insignia_MERN-20.png",
      fromDate: new Date("2023-08-01"),
    },
    {
      title: "Programador Full Stack MERN",
      place: "MindHub LA",
      description:
        'Desarrollé proyectos y aplicaciones reales bajo la metodología "learning-by-doing” utilizando tecnologías de front y backend basadas en el stack MERN(MongoDB, Express.js, React.js y Node.js), con metodología agile, simulando un entorno de trabajo profesional a tiempo completo.',
      imageSrc:
        "https://images.credly.com/images/9ab2bbf5-a5cd-4c36-9c5a-1ce7e0e0cd32/Insignia_MERN-20.png",
      fromDate: new Date("2021-06-01"),
      toDate: new Date("2021-10-31"),
    },
  ],
  languages: [],
  filename: "sebastian_campos",
};

//\n\n

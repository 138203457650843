import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import { useApiRequest } from "./apiRequest";
import { useShowToast } from "./showToast";

import { ExternalAuthParams } from "../stores/registration";
import { useInitSession, useSessionAtom } from "../stores/session";
import { useAtom, useAtomValue } from "jotai";

export type LoginParameters =
  | {
      type: "internal";
      email: string;
      password: string;
    }
  | {
      type: "external";
      provider: ExternalAuthParams["provider"];
      accessToken: string;
    };

export const useSession = () => {
  const sessionAtom = useSessionAtom();
  return useAtomValue(sessionAtom);
};

export const useLogin = () => {
  const navigate = useNavigate();
  const apiRequest = useApiRequest();

  const location = useLocation();

  const initSesssion = useInitSession();
  const showToast = useShowToast();
  const resendVerificationEmail = useResendVerificationEmail();

  return async (data: LoginParameters, keepSessionActive: boolean) => {
    const response = await apiRequest
      .post("/api/auth/login", data)
      .catch((e) => {
        if (axios.isAxiosError(e) && e.response?.data) {
          const code = e.response.data.data?.code;

          if (code === "unverified") {
            const email = e.response.data.data.email;

            showToast({
              type: "error",
              title:
                "La cuenta no se encuentra verificada, revise su correo electrónico y siga las instrucciones",
              children: (
                <button onClick={() => resendVerificationEmail(email)}>
                  Reenviar correo
                </button>
              ),
            });
          } else if (code === "mismatch") {
            showToast({
              type: "error",
              title:
                "El correo o la contraseña no corresponden con un usuario activo",
            });
          }
        }

        throw e;
      });

    initSesssion(response.data, keepSessionActive ? "stored" : "temporal");

    const locationState = location.state as { from?: string } | undefined;
    navigate(locationState?.from ?? "/");

    return response.data;
  };
};

export const useLogout = () => {
  const sessionAtom = useSessionAtom();
  const [_, setSession] = useAtom(sessionAtom);
  const navigate = useNavigate();

  return () => {
    setSession(null);
    navigate("/");
  };
};

export const useVerifyAccount = () => {
  const apiRequest = useApiRequest();

  return async (token: string) => {
    return await apiRequest.post("/api/auth/verify", { verifyToken: token });
  };
};

export const useResendVerificationEmail = () => {
  const apiRequest = useApiRequest();
  const showToast = useShowToast();
  return async (email: string) => {
    await apiRequest.post("/api/users/send-verification", { email });
    showToast({ title: "Correo de verificación reenviado" });
  };
};

import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to="/" className="text-2xl text-B1-m dark:text-white font-bold">
      bastiampos
    </Link>
  );
};

export default Logo;

import { CenteredSection } from "../../../components/CenteredSection";
import { Row } from "../../../components/Row";

const DevFooterSection = () => {
  return (
    <CenteredSection
      className="py-6 w-full bg-[#000000]"
      containerClassName="bg-[#000000]"
    >
      <Row className="justify-between items-center text-xs text-neutral-400 w-full flex-wrap">
        <p>Buenos Aires, Argentina (GMT-3)</p>
        <p> bastiampos ©2022</p>
      </Row>
    </CenteredSection>
  );
};

export default DevFooterSection;

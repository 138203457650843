import { useState } from "react";
import { Col } from "../../../../components/Col";
import EmptyModal from "../../../../components/modals";
import RightDownloadCVModalSection from "./right";
import LeftDownloadCVModalSection from "./left";
import { CurriculumSections } from "../../../../stores/cv";
import { Row } from "../../../../components/Row";
import CloseModalButton from "../../../../components/modals/closeButton";
import SimpleBar from "simplebar-react";
import Button from "../../../../components/Button";
import { MaterialIcon } from "../../../../components/Icons";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CVDocument from "../../../../pdfs/curriculum";
import OutsideDiv from "../../../../components/OutsideDiv";

type Props = {
  onClose: () => void;
};

const DownloadCVModal = (props: Props) => {
  const defaultFilename = "sebastian_campos";
  const [filename, setFilename] = useState(defaultFilename);
  const [sections, setShowSections] = useState<CurriculumSections[]>([
    "selfie",
    "introduction",
    "education",
    "workExperience",
    "projects",
  ]);

  const updateFilename = (newFilename: string) => {
    setFilename(newFilename.replace(/ /g, "_").replace(".pdf", ""));
  };

  const handleSection = (key: CurriculumSections, isChecked: boolean) => {
    if (isChecked) setShowSections([...sections, key]);
    else setShowSections(sections.filter((section) => section !== key));
  };

  const openPdf = () => {
    window.open("/pdf/cv", "_blank");
  };

  return (
    <EmptyModal>
      <Col className="w-screen h-screen overflow-hidden p-4 items-center justify-center">
        <OutsideDiv
          onClose={props.onClose}
          className="bg-neutral-300 dark:bg-neutral-800 shadow-md border border-neutral-500 dark:border-neutral-800 rounded-[12px] text-sm pt-4 xl:pt-0 w-full max-w-[500px] xl:max-w-[944px] max-h-[635px] overflow-hidden"
        >
          <Row className="items-center justify-between px-4 sm:pl-6 xl:hidden">
            <p className="text-lg text-neutral-900 dark:text-neutral-300 font-[600]">
              Descargar currículum
            </p>
            <CloseModalButton className="xl:hidden" onClick={props.onClose} />
          </Row>
          <SimpleBar
            autoHide
            className="w-full max-h-[450px] pt-2 xl:pt-0 pb-6 xl:pb-0"
          >
            <div className="grid grid-cols-1 xl:grid-cols-5 gap-y-6 w-full flex-1 h-full">
              <LeftDownloadCVModalSection
                sections={sections}
                handleSection={handleSection}
                onClose={props.onClose}
              />
              <RightDownloadCVModalSection
                onClose={props.onClose}
                filename={filename}
                updateFilename={updateFilename}
              />
            </div>
          </SimpleBar>
          <Row className="flex-1 justify-between bg-neutral-600 dark:bg-neutral-900 py-3 px-4 sm:px-6">
            <Button
              onClick={props.onClose}
              className="border border-neutral-800 dark:border-neutral-700 rounded-[8px] text-white !justify-center !px-2 min-w-[150px] hidden xl:block"
            >
              Cerrar
            </Button>
            <div className="gap-x-4 grid grid-cols-2 w-full xl:max-w-[328px]">
              <Button
                onClick={openPdf}
                className="bg-neutral-800 dark:bg-neutral-700 rounded-[8px] text-white !justify-center !px-2 xl:min-w-[150px]"
                icon={
                  <MaterialIcon className="text-xl">open_in_new</MaterialIcon>
                }
              >
                Abrir
              </Button>
              <PDFDownloadLink
                document={<CVDocument />}
                fileName={filename + ".pdf"}
                className="bg-neutral-800 dark:bg-neutral-700 rounded-[8px] text-white flex items-center justify-center gap-x-2 px-2 xl:min-w-[150px] hover:opacity-70"
              >
                <MaterialIcon className="text-xl">file_download</MaterialIcon>
                Descargar
              </PDFDownloadLink>
            </div>
          </Row>
        </OutsideDiv>
      </Col>
    </EmptyModal>
  );
};

export default DownloadCVModal;

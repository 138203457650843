import { format } from "date-fns";
import { Col } from "../../../../components/Col";
import { Row } from "../../../../components/Row";
import { MaterialIcon } from "../../../../components/Icons";
import { Job } from "../../../../stores/cv";

type Props = {
  job: Job;
};

const JobTimelineItem = ({ job }: Props) => {
  const formatDate = (date: Date) => format(new Date(date), "dd/MM/yyyy");
  return (
    <Col className="flex-1">
      <Row className="items-start gap-x-4">
        <div className="h-10 w-10 rounded-full bg-[#dddddd80] overflow-hidden min-w-max">
          {job.companyImage ? (
            <div
              style={{ backgroundImage: `url("${job.companyImage}")` }}
              className="h-10 w-10 bg-contain bg-no-repeat bg-center"
            />
          ) : (
            <MaterialIcon>work</MaterialIcon>
          )}
        </div>
        <Col className="gap-y-4 flex-1">
          <Col>
            <p className="font-[600]">
              {job.companyName} - {job.jobTitle}
            </p>
            <p className="text-xs">
              {formatDate(job.fromDate)} -{" "}
              {job.toDate ? formatDate(job.toDate) : "Actualidad"}
            </p>
            {job?.skills ? (
              <Row className="items-center justify-start flex-wrap text-[11px] mt-2 gap-2">
                {job.skills.map((skill) => (
                  <p
                    key={skill}
                    className="text-[#6128FF90] dark:text-white bg-[#B0AAE850] px-1 rounded-full"
                  >
                    {skill}
                  </p>
                ))}
              </Row>
            ) : null}
          </Col>
          <div
            className="text-sm"
            dangerouslySetInnerHTML={{ __html: job.description }}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default JobTimelineItem;

import { MaterialIcon } from "../../../../components/Icons";
import { Row } from "../../../../components/Row";

const OpenToWorkBadge = () => {
  return (
    <Row className="gap-x-2 bg-[#E9F5EF] text-[#268D61] tracking-4 text-sm font-[500] items-center h-[30px] px-2 rounded-full min-w-max">
      <MaterialIcon className="text-xl">work_history</MaterialIcon>
      <p>Disponible para trabajar</p>
    </Row>
  );
};

export default OpenToWorkBadge;

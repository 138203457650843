import clsx from "clsx";
import { Col } from "../../../../components/Col";

import Switch from "react-switch";
import { CurriculumSections, curriculumSections } from "../../../../stores/cv";
import { Row } from "../../../../components/Row";

const LeftDownloadCVModalSection = (props: {
  onClose: () => void;
  sections: CurriculumSections[];
  handleSection: (key: CurriculumSections, isChecked: boolean) => void;
}) => {
  return (
    <Col
      className={clsx(
        "xl:col-span-3 pl-4 pr-2 sm:px-6 xl:p-6 gap-y-10 flex-1 h-full"
      )}
    >
      <Col className="">
        <Row className="justify-between items-center w-full gap-4">
          <p className="hidden xl:block text-base xl:text-lg text-neutral-900 dark:text-neutral-300 font-[600]">
            Descargar currículum
          </p>
        </Row>

        <Col className="text-neutral-700 dark:text-neutral-500 gap-y-6">
          <p className="w-full max-w-[533px]">
            Personaliza el contenido que conservarás de mi curriculum y descarta
            el que no vayas a necesitar. ¡Echa un vistazo a las opciones
            disponibles!{" "}
            <span className="text-red-800 opacity-70">
              (Esta función está temporalmente en mantenimiento)
            </span>
          </p>
          <div className="grid md:grid-cols-2 gap-y-4">
            {curriculumSections.map((section) => (
              <label key={section.key} className="flex items-center gap-x-2">
                <Switch
                  disabled
                  width={50}
                  height={20}
                  checked={props.sections.includes(section.key)}
                  onChange={(isChecked) =>
                    props.handleSection(section.key, isChecked)
                  }
                />
                <span>{section.title}</span>
              </label>
            ))}
          </div>
        </Col>
      </Col>
    </Col>
  );
};

export default LeftDownloadCVModalSection;

import { PDFViewer } from "@react-pdf/renderer";
import CVDocument from ".";

const ViewCVDocument = () => {
  return (
    <PDFViewer className="w-screen h-screen" showToolbar>
      <CVDocument />
    </PDFViewer>
  );
};

export default ViewCVDocument;

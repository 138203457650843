import { StyleSheet, Text, View } from "@react-pdf/renderer";
import CVPDFSection from "./components/section";
import { defaultCurriculum } from "../../stores/cv";
import { format } from "date-fns";

const WorkExperienceCVPdfSection = () => {
  const jobs = defaultCurriculum.jobs;

  const styles = StyleSheet.create({
    view: {},
    jobTitle: { fontSize: "12px", fontWeight: 900 },
    companyName: { fontSize: "9px", fontWeight: 700, color: "#00000090" },
    description: {
      fontSize: "10px",
      fontWeight: 400,
      color: "#000000",
      marginTop: "5px",
    },
  });

  const formatDate = (date: Date) => format(new Date(date), "MM/yyyy");

  return (
    <CVPDFSection subtitle="Experiencia Laboral">
      <View>
        {jobs.map((job, index) => (
          <View
            key={index}
            style={[styles.view, { marginTop: index > 0 ? "10px" : "0px" }]}
          >
            <Text style={styles.jobTitle}>{job.jobTitle}</Text>
            <Text style={styles.companyName}>
              {job.companyName}, {formatDate(job.fromDate)} -{" "}
              {job.toDate ? formatDate(job.toDate) : "Actualidad"}
            </Text>
            <Text style={styles.description}>{job.cvDescription}</Text>
          </View>
        ))}
      </View>
    </CVPDFSection>
  );
};

export default WorkExperienceCVPdfSection;

import SimpleBar from "simplebar-react";
import { PropsWithChildren, useMemo } from "react";
import toast, { resolveValue, Toaster } from "react-hot-toast";
import { useLocation, Navigate, Route, Routes, Outlet } from "react-router-dom";

import LoggedUserLayout from "./layouts/user/logged";
import UserLayout from "./layouts/user/index";

import LinksPage from "./pages/public/links/index";
import CurriculumPage from "./pages/public/curriculum";
import Ani from "./pages/public/ani";

import NoMatchPage from "./pages/others/noMatch/index";

import Notification from "./components/Notification";
import { useSession } from "./hooks/authentication";
import { ToastProps } from "./hooks/showToast";
import { UserRole } from "./stores/registration";

import "simplebar-react/dist/simplebar.min.css";
import ViewCVDocument from "./pdfs/curriculum/viewer";

const RequireUserRole = ({
  children,
  role,
}: PropsWithChildren<{ role: UserRole }>) => {
  const session = useSession();
  const user = session?.user;
  const location = useLocation();

  if (!user || user.role !== role) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

const userRoutes = (
  <>
    <Route path="/user" element={<UserLayout />}></Route>
  </>
);

const authenticatedUserRoutes = (
  <>
    <Route
      path="/user"
      element={
        <RequireUserRole role="user">
          <LoggedUserLayout />
        </RequireUserRole>
      }
    ></Route>
  </>
);

const ownLayoutRoutes = (
  <>
    <Route path="/" element={<Outlet />}>
      <Route index element={<LinksPage />} />
      <Route path="/cv" element={<CurriculumPage />} />
      <Route path="/ani" element={<Ani />} />
      <Route path="/pdf">
        <Route path="cv" element={<ViewCVDocument />} />
      </Route>
    </Route>
  </>
);

const App = () => {
  const session = useSession();

  const redirectRoute = useMemo(() => {
    if (session === null) {
      return <Route index element={<Navigate to="/" replace />} />;
    }
    if (session.user.role === "user")
      return <Route index element={<Navigate to="/user" replace />} />;

    return <Route index element={<Navigate to="/admin" replace />} />;
  }, [session]);

  const noMatchRoute = useMemo(() => {
    if (session === null) {
      return <Route path="*" element={<Navigate to="/" replace />} />;
    }
    return <Route path="*" element={<NoMatchPage />} />;
  }, [session]);

  const child = (
    <>
      <Toaster position="bottom-center" containerStyle={{ bottom: 80 }}>
        {(t) => {
          if (
            t.message &&
            typeof t.message === "object" &&
            "title" in t.message
          ) {
            const message = t.message as ToastProps;
            return (
              <Notification
                title={message.title}
                description={message.desc}
                onClose={() => toast.dismiss(t.id)}
                type={message.type}
              >
                {message.children}
              </Notification>
            );
          }

          return <>{resolveValue(t.message, t)}</>;
        }}
      </Toaster>
      <Routes>
        {ownLayoutRoutes}
        {userRoutes}
        {authenticatedUserRoutes}
        {redirectRoute}
        {noMatchRoute}
      </Routes>
    </>
  );

  return (
    <>
      <div className="lg:hidden">{child}</div>
      <SimpleBar
        autoHide
        forceVisible="y"
        className="hidden lg:block max-h-screen"
      >
        {child}
      </SimpleBar>
    </>
  );
};

export default App;

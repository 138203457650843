import { StyleSheet, Text, View } from "@react-pdf/renderer";
import CVPDFSection from "./components/section";
import { defaultCurriculum } from "../../stores/cv";
import { format } from "date-fns";

const ProjectsCVPdfSection = () => {
  const projects = defaultCurriculum.projects;

  const styles = StyleSheet.create({
    view: {},
    title: { fontSize: "12px", fontWeight: 900 },
    companyName: { fontSize: "9px", fontWeight: 700, color: "#00000090" },
    description: {
      fontSize: "10px",
      fontWeight: 400,
      color: "#000000",
      marginTop: "5px",
    },
  });

  const formatDate = (date: Date) => format(new Date(date), "yyyy");

  return (
    <CVPDFSection subtitle="Proyectos">
      <View>
        {projects.map((project, index) => (
          <View
            key={index}
            style={[styles.view, { marginTop: index > 0 ? "10px" : "0px" }]}
          >
            <Text style={styles.title}>{project.title}</Text>
            <Text style={styles.companyName}>
              {project.job?.companyName
                ? `Con ${project.job?.companyName},`
                : ""}{" "}
              {project.fromDate ? `${formatDate(project.fromDate)} a ` : ""}
              {project.finished ? formatDate(project.finished) : "Actualidad"}
            </Text>
            <Text style={styles.description}>{project.description}</Text>
          </View>
        ))}
      </View>
    </CVPDFSection>
  );
};

export default ProjectsCVPdfSection;

import { NavLink } from "react-router-dom";
import { Row } from "../../components/Row";
import { MaterialIcon } from "../../components/Icons";
import { ImGithub, ImTwitter } from "react-icons/im";
import Logo from "./logo";

export type menuItem = {
  title: string;
  path: string;
  icon?: string;
};

const PublicMenu = () => {
  const menuItems: menuItem[] = [
    {
      title: "Inicio",
      path: "/dev",
    },
  ];

  return (
    <Row className="h-[60px] w-full justify-between items-center">
      <Logo />
      <Row>
        <Row className="gap-8">
          {menuItems.map((item, index) => (
            <NavLink
              key={index}
              to={item?.path}
              end
              className={({ isActive }) =>
                `${
                  isActive ? "text-B1-m" : "text-N1-b"
                } hover:text-B1-m font-medium`
              }
            >
              {item?.title}
            </NavLink>
          ))}
        </Row>
        <div className="w-[1px] bg-slate-200 mx-4" />
        <Row className="items-center gap-4">
          {false && (
            <Row className="text-slate-400 hover:text-slate-500 cursor-pointer">
              <MaterialIcon>dark_mode</MaterialIcon>
            </Row>
          )}
          <a
            href="https://github.com/bastiampos"
            target="_blank"
            className="text-slate-400 hover:text-slate-500 text-xl items-center cursor-pointer"
          >
            <ImGithub />
          </a>
          <a
            href="https://twitter.com/bastiampos"
            target="_blank"
            className="text-slate-400 hover:text-slate-500 text-xl items-center cursor-pointer"
          >
            <ImTwitter />
          </a>
        </Row>
      </Row>
    </Row>
  );
};

export default PublicMenu;

import React from "react";
import clsx from "clsx";

export const Col = React.forwardRef<
  HTMLDivElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>((props, ref) => {
  const { className, ...restProps } = props;
  return (
    <div
      {...restProps}
      ref={ref}
      className={clsx([className, "flex flex-col"])}
    />
  );
});
